<template>
  <div>正在打开粗门小程序</div>
</template>

<script>
/// 跳转到微信小程序消息页面
export default {
  name: "LaunchCumenMessages",
  beforeCreate() {
    // 跳转到小程序的断链接页面
    // window.location.href = 'https://wxaurl.cn/kCslqsehpTc';
    window.location.href = 'https://wxaurl.cn/yfyHK9aUfcv';
  }
};
</script>

<style lang="scss" scoped>
  div {
    font-size: 16px;
  }
</style>
